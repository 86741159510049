<template>
  <div class="p-1">
    <div class="flex flex-wrap justify-between items-center mb-2">
      <h3 class="text-2xl">{{ lang.classrooms.classrooms }}</h3>
      <a-button :to="{ name: 'create_classroom' }" v-if="!user.isStudent && !user.isLowAdmin" class="w-full md:w-auto">
        {{ lang.classrooms.create_classroom }}
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      :pagination="pagination"
      @paginate="search({ page: $event })"
      toolbar-column-filter
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>

      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'edit_classroom', params: { id: item.id } }">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deleteClassroom(item.id)">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    query: {
      limit: 10,
      search: '',
      sort_by: 'description,asc'
    },
    timeout: null
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.classrooms.pagination,
      loading: state => state.classrooms.loading
    }),
    ...mapGetters(['lang']),
    columns() {
      return [
        { title: this.lang.classrooms.table.code, key: 'code', display: true },
        { title: this.lang.classrooms.table.description, key: 'description', display: true },
        { title: this.lang.classrooms.table.max_capacity, key: 'max_capacity', display: true },
        { title: this.lang.classrooms.table.multiple_sections, key: 'allow_many_sections', mutate: ({ key }) => key ? 'Si' : 'No', display: true },
        { title: this.lang.classrooms.table.state, key: 'enabled', mutate: ({ key }) => key ? 'Activo' : 'Inactivo', display: true },
        { title: this.lang.classrooms.table.actions, slot: 'actions', alignment: 'right', columnFilterable: false, display: this.user.isAdmin },
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetchClassrooms: 'classrooms/index',
      deleteClassroom: 'classrooms/delete'
    }),
    search(query = {}) {
      this.fetchClassrooms({ ...this.query, ...query })
    }
  },
  mounted() {
    this.search({ page: this.$route.query.page || 1 })
  }
}
</script>
